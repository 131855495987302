/* eslint-disable no-unused-vars */
import { useEffect } from 'react'
import { config } from './config'

// Type definitions for Reddit Pixel
type RedditPixelInitialize = (command: 'init', pixelId: string) => void
type RedditPixelTrack = (command: 'track', event: string, params?: Record<string, unknown>) => void
type RedditPixelFunction = RedditPixelInitialize & RedditPixelTrack
type RedditCallQueue = unknown[]
type RedditSendEvent = (...args: unknown[]) => void

// Extend the Window interface to include Reddit Pixel
declare global {
    interface Window {
        rdt?: RedditPixelFunction & {
            callQueue?: RedditCallQueue
            sendEvent?: RedditSendEvent
        }
    }
}

export type TrackEventFunction = (eventName: string, eventData?: Record<string, unknown>) => void

export type RedditPixelHook = {
    track: TrackEventFunction
}

export const useRedditPixel = (): RedditPixelHook => {
    const mode = process.env.NODE_ENV

    useEffect(() => {
        // Only run this on the client side
        if (typeof window === 'undefined' || typeof document === 'undefined') return

        // Initialize Reddit Pixel
        if (!window.rdt) {
            if (mode === 'production') {
                const p = function (this: unknown, ...args: unknown[]) {
                    const self = this as typeof window.rdt
                    if (self && self.sendEvent) {
                        self.sendEvent.apply(self, args)
                    } else if (window.rdt && window.rdt.callQueue) {
                        window.rdt.callQueue.push(args)
                    }
                } as unknown as typeof window.rdt

                p.callQueue = []
                window.rdt = p

                // Create script element
                const script = document.createElement('script')
                script.src = 'https://www.redditstatic.com/ads/pixel.js'
                script.async = true

                // Add script to document
                const firstScript = document.getElementsByTagName('script')[0]
                if (firstScript?.parentNode) {
                    firstScript.parentNode.insertBefore(script, firstScript)
                } else {
                    document.head.appendChild(script)
                }
            } else {
                window.rdt = function (this: unknown, command: string, ...args: unknown[]) {
                    console.log(`[Mock Reddit Pixel] ${command}:`, ...args)
                } as unknown as typeof window.rdt
            }
        }

        if (window.rdt) {
            window.rdt('init', config.pixelId)
            window.rdt('track', 'PageVisit')
        }
    }, [])

    // Return tracking function
    return {
        track: (eventName: string, eventData: Record<string, unknown> = {}) => {
            if (window.rdt) {
                window.rdt('track', eventName, eventData)
            }
        }
    }
}
