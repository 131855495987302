/* eslint-disable no-unused-vars */
import React from 'react'
import { config } from './config'

// Type definitions for Reddit Pixel
type RedditPixelInitialize = (command: 'init', pixelId: string) => void
type RedditPixelTrack = (command: 'track', event: string, params?: Record<string, unknown>) => void
type RedditPixelFunction = RedditPixelInitialize & RedditPixelTrack
type RedditCallQueue = unknown[]
type RedditSendEvent = (...args: unknown[]) => void

// Extend the Window interface to include Reddit Pixel
declare global {
    interface Window {
        rdt?: RedditPixelFunction & {
            callQueue?: RedditCallQueue
            sendEvent?: RedditSendEvent
        }
    }
}

export type TrackEventFunction = (eventName: string, eventData?: Record<string, unknown>) => void

export interface RedditPixelProps {
    children?: React.ReactNode
    // Control whether the script should be added
    enableScript?: boolean
}

export class RedditPixel extends React.Component<RedditPixelProps> {
    static defaultProps = {
        enableScript: true,
    }

    static contextType = React.createContext(null)

    // Create a static track method that can be accessed directly from the class
    static track(eventName: string, eventData: Record<string, unknown> = {}): void {
        if (window.rdt) {
            window.rdt('track', eventName, eventData)
        }
    }

    componentDidMount(): void {
        // Only run this on the client side
        if (typeof window === 'undefined' || typeof document === 'undefined') return

        const { enableScript } = this.props
        const mode = process.env.NODE_ENV

        // Initialize Reddit Pixel
        if (!window.rdt) {
            if (mode === 'production') {
                const p = function (this: unknown, ...args: unknown[]) {
                    const self = this as typeof window.rdt
                    if (self && self.sendEvent) {
                        self.sendEvent.apply(self, args)
                    } else if (window.rdt && window.rdt.callQueue) {
                        window.rdt.callQueue.push(args)
                    }
                } as unknown as typeof window.rdt

                p.callQueue = []
                window.rdt = p

                // Only add the script if enableScript is true
                if (enableScript) {
                    // Create script element
                    const script = document.createElement('script')
                    script.src = 'https://www.redditstatic.com/ads/pixel.js'
                    script.async = true

                    // Add script to document
                    const firstScript = document.getElementsByTagName('script')[0]
                    if (firstScript?.parentNode) {
                        firstScript.parentNode.insertBefore(script, firstScript)
                    } else {
                        document.head.appendChild(script)
                    }
                }
            } else {
                if (enableScript) {
                    window.rdt = function (this: unknown, command: string, ...args: unknown[]) {
                        console.log(`[Mock Reddit Pixel] ${command}:`, ...args)
                    } as unknown as typeof window.rdt
                }
            }
        }

        if (window.rdt) {
            window.rdt('init', config.pixelId)
            window.rdt('track', 'PageVisit')
        }
    }

    // Instance method for tracking
    track = (eventName: string, eventData: Record<string, unknown> = {}): void => {
        if (window.rdt) {
            window.rdt('track', eventName, eventData)
        }
    }

    render(): React.ReactNode {
        return this.props.children || null
    }
}
